import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import whyUsImg from "../Images/whyUs.png";
import { MobileStepper } from "./MobileStepper";
import { DesktopStepper } from "./DesktopStepper";

const OurProcess = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", m: "50px 0 10px 0", textAlign: "center" }}
        id={"our-process-section"}
      >
        Our Process
      </Typography>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
        gap={4}
        p={isSmallScreen ? 2 : 4}
        mb={6}
      >
        <Box
          flex={1}
          maxWidth={isSmallScreen ? "90%" : "40%"}
          mx="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={whyUsImg}
            alt="Interior Design"
            style={{
              width: "100%",
              borderRadius: "8px",
              maxHeight: isSmallScreen ? "250px" : "400px",
              objectFit: "cover",
            }}
            onContextMenu={(event) => event.preventDefault()}
          />
        </Box>
        {!isSmallScreen ? <DesktopStepper /> : <MobileStepper />}
      </Box>
    </>
  );
};

export default OurProcess;
