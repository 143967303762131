import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
  Modal,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { vistaColorCode } from "../constant";
import { useNavigate } from "react-router-dom";

export const GallerySection = () => {
  const galleryImages = [
    "https://i.ibb.co/gFBtHRL/Washbasin-Drawingroom-New-logo.png",
    "https://i.ibb.co/TH9vwy0/PPK-TV-logo.png",
    "https://i.ibb.co/jgGKXyw/PPK-kitchen-logo.png",
  ];

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (index: number) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (selectedImageIndex < galleryImages.length - 1) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) return; // Only listen when modal is open
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedImageIndex]);

  return (
    <Box sx={{ textAlign: "center", py: 6 }} id={"gallery-section"}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", fontSize: { xs: "1.8rem", md: "2.4rem" } }}
      >
        Our Gallery
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontSize: { xs: "1rem", md: "1.2rem" }, color: "gray", mb: 3 }}
      >
        Explore our latest interior designs and concepts
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 4,
          flexWrap: "wrap",
        }}
      >
        {galleryImages.map((image, index) => (
          <Card
            key={index}
            sx={{
              width: { xs: "100%", sm: 300, md: 400 },
              borderRadius: "10px",
              cursor: "pointer",
              m: "0 20px",
            }}
            onClick={() => handleOpen(index)}
          >
            <CardMedia
              component="img"
              height="200"
              image={image}
              alt={`Gallery ${index + 1}`}
              sx={{ objectFit: "cover" }}
              onContextMenu={(event) => event.preventDefault()}
            />
          </Card>
        ))}
      </Box>
      <Button
        variant="contained"
        sx={{ mt: 4, backgroundColor: vistaColorCode.vistDarkRedColour }}
        onClick={() => navigate("/gallery")}
      >
        View More
      </Button>

      {/* Modal for fullscreen image */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 2,
            borderRadius: "10px",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: isMobile ? 60 : 30,
              right: isMobile ? 40 : 30,
              color: "white",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedImageIndex !== 0 && (
            <IconButton
              sx={{ position: "absolute", left: 30, color: "white" }}
              onClick={handlePrev}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}

          <CardMedia
            component="img"
            image={galleryImages[selectedImageIndex]}
            alt="Selected Gallery Image"
            sx={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "cover",
            }}
            onContextMenu={(event) => event.preventDefault()}
          />
          {selectedImageIndex !== galleryImages.length - 1 && (
            <IconButton
              sx={{ position: "absolute", right: 30, color: "white" }}
              onClick={handleNext}
              disabled={selectedImageIndex === galleryImages.length - 1}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
