import React, { useState } from "react";
import { Paper, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { vistaColorCode } from "../constant";

const steps = [
  {
    label: "Book Vista Fusion Studio",
    description: "Outline your project needs and objectives.",
  },
  {
    label: "Consultation",
    description: "Connect and explore possibilities together.",
  },
  {
    label: "Seal the Deal",
    description: "Finalize agreements and sign contracts.",
  },
  {
    label: "Designing",
    description: "Craft detailed designs that align with your vision.",
  },
  {
    label: "Signoff",
    description: "Review and approve the final design before execution.",
  },
  {
    label: "Execution",
    description: "Bring the approved designs to life with precision.",
  },
  {
    label: "Handover",
    description:
      "Deliver the completed project with all final details in place.",
  },
];

export const MobileStepper: React.FC = () => {
  const [isSliding, setIsSliding] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box width="100%" maxWidth="600px" mx="auto">
      <Swiper
        modules={[Autoplay, Navigation]}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
        slidesPerView={1}
        spaceBetween={10}
        onSlideChange={(swiper: any) => {
          setIsSliding(true);
        }}
        onSlideChangeTransitionEnd={() => setIsSliding(false)}
      >
        {steps.map((step, index) => (
          <SwiperSlide key={index}>
            <Paper
              sx={{
                textAlign: "center",
                backgroundColor: "#f8f6f1",
                boxShadow: "none",
                width: "100%",
                borderRadius: "12px",
              }}
            >
              <Box display="flex" justifyContent="center" mb={2}>
                <Box
                  sx={{
                    backgroundColor: isSliding ? "grey" : "green",
                    color: "white",
                    width: isSmallScreen ? 30 : 35,
                    height: isSmallScreen ? 30 : 35,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "bold",
                    fontSize: isSmallScreen ? "0.9rem" : "1rem",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                >
                  {index + 1}
                </Box>
              </Box>
              <Typography
                variant={isSmallScreen ? "h6" : "h5"}
                gutterBottom
                fontWeight="bold"
                color={vistaColorCode.vistDarkRedColour}
              >
                {step.label}
              </Typography>
              <Typography variant={isSmallScreen ? "body2" : "body1"}>
                {step.description}
              </Typography>
            </Paper>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
