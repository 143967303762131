import { Check } from "@mui/icons-material";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { vistaColorCode } from "../constant";

const steps = [
  {
    label: "Book Vista Fusion Studio",
    description: "Outline your project needs and objectives.",
  },
  {
    label: "Consultation",
    description: "Connect and explore possibilities together.",
  },
  {
    label: "Seal the Deal",
    description: "Finalize agreements and sign contracts.",
  },
  {
    label: "Designing",
    description: "Craft detailed designs that align with your vision.",
  },
  {
    label: "Signoff",
    description: "Review and approve the final design before execution.",
  },
  {
    label: "Execution",
    description: "Bring the approved designs to life with precision.",
  },
  {
    label: "Handover",
    description:
      "Deliver the completed project with all final details in place.",
  },
];

const CustomStepIcon = ({ active, completed, icon }: any) => {
  return (
    <div
      style={{
        backgroundColor: completed
          ? "green"
          : active
          ? vistaColorCode.vistDarkRedColour
          : "#ccc",
        color: "white",
        width: 30,
        height: 30,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {completed ? <Check style={{ color: "white" }} /> : icon}
    </div>
  );
};

export const DesktopStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(
    Array(steps.length).fill(false)
  );
  const [isVisible, setIsVisible] = useState(false);
  const stepperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (stepperRef.current) {
      observer.observe(stepperRef.current);
    }

    return () => {
      if (stepperRef.current) {
        observer.unobserve(stepperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setActiveStep((prevStep) => {
        const nextStep = prevStep + 1;

        setCompletedSteps((prevCompleted) => {
          const updatedCompleted = [...prevCompleted];
          updatedCompleted[prevStep] = true;
          return updatedCompleted;
        });

        if (nextStep === steps.length) {
          setTimeout(() => {
            setCompletedSteps(Array(steps.length).fill(false));
            setActiveStep(0);
          }, 750);
          return prevStep;
        }
        return nextStep;
      });
    }, 1500);

    return () => clearInterval(interval);
  }, [isVisible]);

  return (
    <Box ref={stepperRef} flex={1} maxWidth="50%">
      <Typography
        variant="h4"
        align="center"
        mb={12}
        fontWeight="bold"
        color={vistaColorCode.vistDarkRedColour}
      >
        The Way We Work
      </Typography>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ marginBottom: "4rem" }}
      >
        {steps.map((step, index) => (
          <Step key={index} completed={completedSteps[index]}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon {...props} completed={completedSteps[index]} />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper
        sx={{
          textAlign: "center",
          backgroundColor: "#f8f6f1",
          boxShadow: "none",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          fontWeight="bold"
          color={vistaColorCode.vistDarkRedColour}
        >
          {steps[activeStep].label}
        </Typography>
        <Typography variant="body1">{steps[activeStep].description}</Typography>
      </Paper>
    </Box>
  );
};
