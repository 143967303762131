import commercialImg from "./Images/commercial.png";
import hospitalityImg from "./Images/hospitality.png";
import residenceImg from "./Images/residentService.png";
import retailImg from "./Images/retailDesign.png";

export const vistaColorCode = {
  vistDarkRedColour: "#803341",
  vistLightPinkColour: "#faeaf1",
  vistaLightOrangeColour: "#f47d71",
  vistaDarkPinkColour: "#f26a7e",
};

export interface ICommonState {
  isModalOpen?: boolean;
  setIsModalOpen: (val: boolean) => void;
  services?: any;
  setSelectedService?: (val: string) => void;
}

export const trackConversion = () => {
  (window as any).gtag("event", "conversion", {
    send_to: "AW-16905654875/AuqpCNaqhaYaENukn_0-",
  });
};

export const services = [
  {
    service_name: "Residence",
    service_content:
      "Elevate your home with bespoke designs that blend luxury, comfort, and functionality.",
    image_url: residenceImg,
  },
  {
    service_name: "Commercial",
    service_content:
      "Bring your business to life with immersive 3D visualizations and smart interior solutions.",
    image_url: commercialImg,
  },
  {
    service_name: "Retail",
    service_content:
      "Create captivating retail spaces with custom furniture and designs tailored to your brand.",
    image_url: retailImg,
  },
  {
    service_name: "Hospitality",
    service_content:
      "Elevate guest experiences with elegant, functional hospitality interiors tailored to your brand.",
    image_url: hospitalityImg,
  },
];
