import { Close } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CardMedia,
  Container,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const imageData = [
  { url: "https://i.ibb.co/jgGKXyw/PPK-kitchen-logo.png" },
  {
    url: "https://i.ibb.co/V2fLCLj/PPK-Ground-V2-Living-room-20241110-205839-logo.png",
  },
  {
    url: "https://i.ibb.co/y4B9NVg/PPK-Ground-V2-Dining-room-20241110-225706-logo.png",
  },
  { url: "https://i.ibb.co/LQxh1kh/MBR-1-logo.png" },
  {
    url: "https://i.ibb.co/V3hWjW0/PPK-Ground-V2-Dining-room-20241110-204121-logo.png",
  },
  { url: "https://i.ibb.co/fn041cz/Slide4.jpg" },
  { url: "https://i.ibb.co/85yt8m5/Slide3.jpg" },
  { url: "https://i.ibb.co/bJG6Vr2/Slide1.jpg" },
  { url: "https://i.ibb.co/FgGBng0/Slide2.jpg" },
];

const Gallery = ({ images = imageData }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (index: number) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (selectedImageIndex < images.length - 1) {
      setSelectedImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) return;
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, selectedImageIndex]);

  return (
    <Container maxWidth="lg">
      {/* Back Button */}
      <IconButton
        sx={{ position: "absolute", top: 20, right: 20 }}
        onClick={() => navigate(-1)}
      >
        <Close />
      </IconButton>

      <Box mt={12} mb={4}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          fontWeight={600}
          id={"gallery"}
        >
          Our Gallery
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Explore our latest projects
        </Typography>
      </Box>

      {/* Gallery Grid Layout */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // For small screens (mobile), 1 column
            sm: "repeat(2, 1fr)", // For medium screens, 2 columns
            md: "repeat(3, 1fr)", // For large screens, 3 columns
          },
          gap: 3,
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image.url}
            alt={`Gallery image ${index + 1}`}
            sx={{
              width: "100%",
              aspectRatio: "4 / 3",
              borderRadius: 2,
              boxShadow: 3,
              objectFit: "cover",
              cursor: "pointer",
              transition: "transform 0.3s ease",
              "&:hover": { transform: "scale(1.05)" },
            }}
            onClick={() => handleOpen(index)}
            onContextMenu={(event) => event.preventDefault()}
          />
        ))}
      </Box>

      {/* Modal for fullscreen image */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            outline: "none",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: isMobile ? 50 : 10,
              right: isMobile ? 35 : 15,
              color: "white",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedImageIndex !== 0 && (
            <IconButton
              sx={{ position: "absolute", left: 30, color: "white" }}
              onClick={handlePrev}
              disableRipple
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}

          <CardMedia
            component="img"
            image={images[selectedImageIndex].url}
            alt="Selected Gallery Image"
            sx={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "cover",
              outline: "none",
              "&:focus": { outline: "none" },
            }}
            onContextMenu={(event) => event.preventDefault()}
          />
          {selectedImageIndex !== images.length - 1 && (
            <IconButton
              sx={{ position: "absolute", right: 30, color: "white" }}
              onClick={handleNext}
              disableRipple
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Gallery;
