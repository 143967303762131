import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import {
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface LinkItem {
  name: string;
  path: string;
}

const socialLinks = [
  {
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/share/1BH5WrTomp/?mibextid=LQQJ4d/",
  },
  {
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/vistafusionstudio/",
  },
  { icon: <LinkedInIcon />, url: "https://www.linkedin.com/" },
];

const pageLinks: LinkItem[] = [
  { name: "Home", path: "#home-section" },
  { name: "Services", path: "#service-section" },
  { name: "About Us", path: "#about-us-section" },
  { name: "Our Process", path: "#our-process-section" },
  { name: "Gallery", path: "#gallery-section" },
  { name: "Contact Us", path: "#contact-us-section" },
];

const serviceLinks: LinkItem[] = [
  { name: "Kitchen", path: "#gallery" },
  { name: "Living Area", path: "#gallery" },
  { name: "Bathroom", path: "#gallery" },
  { name: "Dining Hall", path: "#gallery" },
  { name: "Bedroom", path: "#gallery" },
];

const SocialMediaLinks: React.FC = () => (
  <List sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 3 }}>
    {socialLinks.map((social, index) => (
      <ListItem key={index} sx={{ display: "inline-flex", padding: 0 }}>
        <Link
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
        >
          {social.icon}
        </Link>
      </ListItem>
    ))}
  </List>
);

const LinkList: React.FC<{ links: LinkItem[] }> = ({ links }) => {
  const navigate = useNavigate();
  return (
    <>
      <List sx={{ padding: 0, textAlign: "left" }}>
        {links.map((link, index) => (
          <ListItem key={index} sx={{ padding: 0, mb: 1.5 }}>
            <Link
              component="button"
              onClick={() => {
                if (link.path === "#gallery") {
                  navigate("/gallery");
                } else {
                  const section = document.querySelector(link.path);
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }
              }}
              sx={{
                textDecoration: "none",
                color: "#4d5053",
                "&:hover": { color: "black" },
                fontFamily: "sans-serif",
                cursor: "pointer",
              }}
            >
              {link.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </>
  );
};

const Footer: React.FC = () => {
  return (
    <footer
      style={{
        width: "100%",
        background: "#EAE5DA",
        paddingBottom: "2%",
        marginTop: "4%",
      }}
    >
      <Container maxWidth={false} sx={{ width: "100%" }}>
        <Grid
          container
          spacing={4}
          sx={{ justifyContent: "space-between", textAlign: "left" }}
        >
          <Grid item xs={12} sm={4} md={3}>
            <SocialMediaLinks />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, fontSize: "18px", color: "#292f36" }}
            >
              Pages
            </Typography>
            <LinkList links={pageLinks} />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, fontSize: "18px", color: "#292f36" }}
            >
              Services
            </Typography>
            <LinkList links={serviceLinks} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, fontSize: "18px", color: "#292f36" }}
            >
              Contact
            </Typography>
            <Typography sx={{ marginBottom: 1, color: "#4d5053" }}>
              Villa - 437 Praneeth Pranav Knightwoods, Beeramguda, Hyderabad,
              Telangana 502032
            </Typography>
            <Link
              href="mailto:contact@vistafusionstudio.com"
              sx={{ color: "#4d5053", textDecoration: "none" }}
            >
              contact@vistafusionstudio.com
            </Link>
            <Typography sx={{ color: "#4d5053" }}>+91-9030356464</Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          align="center"
          sx={{ color: "#4d5053", fontSize: "14px", mt: 2, mb: 4 }}
        >
          © {new Date().getFullYear()} Vista Fusion Studio
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
