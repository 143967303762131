import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import aboutUs1 from "../Images/aboutUs1.png";
import aboutUs2 from "../Images/aboutUs2.png";
import { ICommonState, vistaColorCode } from "../constant";

const fadeInVariantsLeft = {
  hidden: { opacity: 0, x: -30 }, // Reduced x value
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
};

const fadeInVariantsRight = {
  hidden: { opacity: 0, x: 30 }, // Reduced x value
  visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
};

export const AboutUs: React.FC<ICommonState> = ({ setIsModalOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ width: "100vw" }} id="about-us-section">
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: "bold", mt: isMobile ? 4 : 0 }}
      >
        About Us
      </Typography>

      {/* Section 2 */}
      <Box
        sx={{
          py: 6,
          px: { xs: 2, sm: 4, md: 6 },
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          alignItems: "center",
          gap: { xs: 4, md: 6 },
        }}
      >
        {/* Image Section */}
        <Box sx={{ flex: 1, textAlign: "center", order: { xs: 2, md: 1 } }}>
          <img
            src={aboutUs2}
            alt="Our Philosophy"
            style={{
              width: "100%",
              maxWidth: "450px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            onContextMenu={(event) => event.preventDefault()}
          />
        </Box>

        {/* Text Section */}
        <Box sx={{ overflow: "hidden" }}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInVariantsRight}
          >
            <Box
              sx={{
                flex: 1,
                textAlign: { xs: "center", md: "right" },
                order: { xs: 1, md: 2 },
                px: { xs: 2, sm: 3 },
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1rem", md: "2rem" },
                  mb: 6,
                }}
                color={vistaColorCode.vistDarkRedColour}
              >
                We Design Spaces for Elegant, Stylish Living
              </Typography>

              <Typography
                component="ul"
                sx={{
                  color: "text.secondary",
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                  pl: { xs: 2, sm: 3 },
                  textAlign: "left",
                }}
              >
                {[
                  "Crafting exceptional interiors that inspire and elevate everyday living.",
                  "Seamlessly blending functionality, aesthetics, and comfort for perfect harmony.",
                  "Personalized and bespoke designs reflecting your unique lifestyle and vision.",
                  "Environmentally-conscious, sustainable solutions for a greener, healthier future.",
                  "Balancing modern elegance, sophistication, and timeless quality in every space.",
                ].map((text, index) => (
                  <li key={index} style={{ marginBottom: "12px" }}>
                    {text}
                  </li>
                ))}
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: vistaColorCode.vistDarkRedColour,
                    fontWeight: "bold",
                    mt: 4,
                    border: "1px solid #6a2a33",
                    fontSize: { xs: "0.85rem", sm: "1rem" },
                    "&:hover": {
                      backgroundColor: vistaColorCode.vistLightPinkColour,
                    },
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Explore More
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Box>

      {/* Section 1 */}
      <Box
        sx={{
          px: { xs: 2, sm: 4, md: 6 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: { xs: 4, md: 6 },
        }}
      >
        {/* Image Section */}
        <Box sx={{ flex: 1, textAlign: "center", order: { xs: 2, md: 1 } }}>
          <img
            src={aboutUs1}
            alt="About Us"
            style={{
              width: "100%",
              maxWidth: "450px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            onContextMenu={(event) => event.preventDefault()}
          />
        </Box>

        {/* Text Section */}
        <Box sx={{ overflow: "hidden" }}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInVariantsLeft}
          >
            <Box
              sx={{
                flex: 1,
                textAlign: { xs: "center", md: "left" },
                order: { xs: 1, md: 2 },
                px: { xs: 2, sm: 3 },
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1rem", md: "2rem" },
                  mb: 4,
                }}
                color={vistaColorCode.vistDarkRedColour}
              >
                Discover Our Expertise
              </Typography>

              <Typography
                component="ul"
                sx={{
                  color: "text.secondary",
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                  pl: { xs: 2, sm: 3 },
                  textAlign: "left",
                }}
              >
                {[
                  "Innovative interior designs tailored to your vision",
                  "Experienced team of professionals in architecture and design",
                  "Seamless project execution from planning to completion",
                  "High-quality material selection for durable, elegant spaces",
                  "Customized solutions for residential, commercial, and hospitality projects",
                ].map((text, index) => (
                  <li key={index} style={{ marginBottom: "12px" }}>
                    {text}
                  </li>
                ))}
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #6a2a33",
                    color: vistaColorCode.vistDarkRedColour,
                    fontSize: { xs: "0.85rem", sm: "1rem" },
                    fontWeight: "bold",
                    mt: 4,
                    "&:hover": {
                      backgroundColor: vistaColorCode.vistLightPinkColour,
                    },
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Get in Touch
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};
