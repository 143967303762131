import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import popUpImg from "../Images/popup.png";
import { vistaColorCode } from "../constant";

interface ModalContentProps {
  onClose: () => void;
  selectedService: string;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  serviceType: string;
}

export const InnerModalContent: React.FC<ModalContentProps> = ({
  onClose,
  selectedService,
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    serviceType: selectedService || "Residence",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detects phone screen

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");

  const validateForm = (): boolean => {
    let newErrors: Partial<FormData> = {};

    if (!formData.name.trim()) newErrors.name = "Full Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    )
      newErrors.email = "Invalid email format";

    if (!formData.phone.trim()) newErrors.phone = "Mobile Number is required";
    else if (!/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Mobile Number must be 10 digits";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name as keyof FormData]: "" });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSending(true);
    setMessage("");

    const templateParams = {
      from_name: formData.name,
      email: formData.email,
      phone: formData.phone,
      service_type: formData.serviceType,
      message: "Message not available",
    };

    try {
      await emailjs.send(
        "service_ez66qzn", // Replace with your EmailJS service ID
        "template_a4hk5m4", // Replace with your EmailJS template ID
        templateParams,
        "R9Yl9lyT79sQieGAV" // Replace with your EmailJS public key
      );
      setMessage("Message sent successfully!");
      setTimeout(() => {
        onClose();
        setFormData({
          name: "",
          email: "",
          phone: "",
          serviceType: "Residence",
        });
      }, 1000);
    } catch (error) {
      setMessage("Failed to send message. Please try again.");
    }

    setSending(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {!isMobile && (
        <img
          src={popUpImg}
          alt="Service"
          style={{ width: "100%" }}
          onContextMenu={(event) => event.preventDefault()}
        />
      )}
      {/* Background Image */}

      {/* Form Container */}
      <Box
        sx={{
          position: isMobile ? "static" : "absolute",
          top: isMobile ? "auto" : "50%",
          right: isMobile ? "auto" : "3%",
          transform: isMobile ? "none" : "translateY(-50%)",
          backgroundColor: "white",
          padding: "20px 18px",
          boxShadow: 3,
          width: { xs: "90%", sm: "60%", md: "40%" },
          borderRadius: "4px",
        }}
      >
        {/* Close Button */}
        <IconButton
          sx={{ position: "absolute", top: 5, right: 5 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
          <b>Get in touch with us</b>
        </Typography>

        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            sx={{ mb: 2 }}
            variant="standard"
            color="secondary"
            disabled={sending}
          />

          <TextField
            fullWidth
            placeholder="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{ mb: 2 }}
            variant="standard"
            color="secondary"
            disabled={sending}
          />

          <TextField
            fullWidth
            placeholder="Mobile"
            name="phone"
            type="number"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
            sx={{ mb: 2 }}
            variant="standard"
            color="secondary"
            disabled={sending}
          />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Select
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange as any}
              variant="standard"
              color="secondary"
              disabled={sending}
            >
              <MenuItem value="Residence">Residence</MenuItem>
              <MenuItem value="Commercial">Commercial</MenuItem>
              <MenuItem value="Retail">Retail</MenuItem>
              <MenuItem value="Hospitality">Hospitality</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={sending}
            sx={{
              background: vistaColorCode.vistDarkRedColour,
            }}
          >
            {sending ? "Sending..." : "Send"}
          </Button>

          {message && (
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: sending ? "gray" : "green",
                mt: 2,
              }}
            >
              {message}
            </Typography>
          )}
        </form>
      </Box>
    </Box>
  );
};
