import { Box, Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import hetich from "../Images/partners/HetichLogo.png";
import royalTouch from "../Images/partners/Royal-Touch.jpg";
import vamaLog from "../Images/partners/VamaLogo.png";
import century from "../Images/partners/centuryPly.png";
import greenPly from "../Images/partners/greenPlylogo.jpeg";
import saintGobain from "../Images/partners/saintGobainLogo.png";
import havells from "../Images/partners/havelsLogo.png";
import merinoWool from "../Images/partners/merinoLogo.jpeg";
import johnSon from "../Images/partners/johnsonLogo.png";

export const PartnerSection = () => {
  const partnerImages = [
    hetich,
    royalTouch,
    vamaLog,
    greenPly,
    century,
    saintGobain,
    havells,
    merinoWool,
    johnSon,
  ];
  const isMobile = useMediaQuery("(max-width: 900px)"); // Adjust for responsive behavior

  return (
    <Box
      sx={{
        textAlign: "center",
        py: 6,
        background: "#EAE5DA",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", fontSize: { xs: "1.8rem", md: "2.4rem" } }}
      >
        Our Trusted Partners
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          fontSize: { xs: "0.9rem", sm: "1rem" },
          mb: 4,
          p: 1,
        }}
      >
        We collaborate with industry-leading brands to bring you the highest
        quality and innovation in interior solutions.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 3,
          overflow: isMobile ? "hidden" : "visible",
        }}
      >
        {isMobile ? (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: "-100%" }}
            transition={{ repeat: Infinity, duration: 13, ease: "linear" }}
            style={{ display: "flex", gap: "2rem" }}
          >
            {partnerImages.map((image, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                }}
              >
                <img
                  src={image}
                  alt={`Partner ${index + 1}`}
                  style={{
                    width: "180px",
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            ))}
          </motion.div>
        ) : (
          // Static images for desktop view
          partnerImages.map((image, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#fff",
              }}
            >
              <img
                src={image}
                alt={`Partner ${index + 1}`}
                style={{ width: "220px", height: "120px", padding: 1 }}
              />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};
