import { Email, Language, Phone, Place } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import emailjs from "emailjs-com";
import { ChangeEvent, FormEvent, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { vistaColorCode } from "../constant";

interface FormData {
  from_name: string;
  email: string;
  phone: string;
  message: string;
}

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
}

const ContactUs = () => {
  const defaultFormData: FormData = {
    from_name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isValidEmail = (email: string): boolean =>
    /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !formData.from_name ||
      !formData.email ||
      !isValidEmail(formData.email) ||
      !formData.message
    ) {
      setSnackbar({
        open: true,
        message: "Please fill out all required fields correctly.",
        severity: "error",
      });
      return;
    }

    setIsSendBtnDisabled(true);
    emailjs
      .send(
        "service_ez66qzn",
        "template_a4hk5m4",
        { ...formData, service_type: "Residence" } as any,
        "R9Yl9lyT79sQieGAV"
      )
      .then(() => {
        setSnackbar({
          open: true,
          message: "Message sent successfully!",
          severity: "success",
        });
        setFormData(defaultFormData);
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Failed to send message. Please try again.",
          severity: "error",
        });
      })
      .finally(() => setIsSendBtnDisabled(false));
  };

  return (
    <>
      <Typography variant="h4" align="center" sx={{ fontWeight: "bold" }}>
        Contact Us
      </Typography>
      <Container maxWidth="lg" id="contact-us-section">
        {/* Contact Section */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          gap={4}
          mt={6}
        >
          {/* Left: Google Map */}
          <Box flex={1} width="100%">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.7697215070184!2d78.30128627555037!3d17.518502699013943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8d8fc51310d7%3A0x161cba0b8f3f3265!2sVista%20Fusion%20Studio!5e0!3m2!1sen!2sin!4v1732974989465!5m2!1sen!2sin"
              title="map"
              width="100%"
              height="350"
              allowFullScreen
              style={{ borderRadius: "10px" }}
            />
          </Box>

          {/* Right: Contact Details */}

          <Stack flex={1} spacing={2} alignItems="flex-start" ml={4} mt={2}>
            {/* Email Section */}
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex" alignItems="center" gap={1}>
                <Email sx={{ color: "#D44638", fontSize: 24 }} />
                <Typography variant="h6" fontWeight="bold">
                  Email
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color={vistaColorCode.vistDarkRedColour}
              >
                <a
                  href="mailto:contact@vistafusionstudio.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  contact@vistafusionstudio.com
                </a>
              </Typography>
            </Box>

            {/* Phone Section */}
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex" alignItems="center" gap={1}>
                <Phone sx={{ color: "#4CAF50", fontSize: 24 }} />
                <Typography variant="h6" fontWeight="bold">
                  Call Us
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color={vistaColorCode.vistDarkRedColour}
              >
                +91-9030356464
              </Typography>
            </Box>

            {/* Website Section */}
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex" alignItems="center" gap={1}>
                <Language sx={{ color: "#2196F3", fontSize: 24 }} />
                <Typography variant="h6" fontWeight="bold">
                  Website
                </Typography>
              </Box>
              <Link
                href="https://www.vistafusionstudio.com"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  textDecoration: "none",
                }}
                color={vistaColorCode.vistDarkRedColour}
              >
                www.vistafusionstudio.com
              </Link>
            </Box>

            {/* Location Section */}
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Box display="flex" alignItems="center" gap={1}>
                <Place sx={{ color: "#FF5722", fontSize: 24 }} />
                <Typography variant="h6" fontWeight="bold">
                  Location
                </Typography>
              </Box>
              <Typography
                variant="subtitle1"
                color={vistaColorCode.vistDarkRedColour}
              >
                <>Praneeth Pranav Knightwoods, 437, Beeramguda </>
                <br />
                Hyderabad, Telangana 502032
              </Typography>
            </Box>
          </Stack>
        </Box>

        {/* Contact Form */}
        <Box
          component="form"
          onSubmit={sendEmail}
          mt={4}
          width="100%"
          // sx={{
          //   padding: 4,
          // }}
        >
          <Stack
            spacing={4}
            width="100%"
            direction={{ xs: "column", md: "row" }}
          >
            <Stack spacing={2} flex={1}>
              <TextField
                fullWidth
                label="Name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                sx={{ "& .MuiInputBase-root": { height: 50 } }}
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                sx={{ "& .MuiInputBase-root": { height: 50 } }}
              />
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                variant="outlined"
                color="secondary"
                sx={{ "& .MuiInputBase-root": { height: 50 } }}
              />
            </Stack>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Hello, I am interested in..."
              name="message"
              color="secondary"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              sx={{
                flex: 1,
                "& .MuiInputBase-root": { height: "100%" },
              }}
            />
          </Stack>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              type="submit"
              endIcon={<BsArrowRight />}
              disabled={isSendBtnDisabled}
              sx={{
                backgroundColor: vistaColorCode.vistDarkRedColour,
                color: "white",
                //   "&:hover": { backgroundColor: "#1a202c" },
                mb: 4,
              }}
            >
              Send Now
            </Button>
          </Box>
        </Box>

        {/* Snackbar for feedback messages */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default ContactUs;
