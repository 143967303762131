import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { ICommonState, trackConversion, vistaColorCode } from "../constant";

const ServiceCards: React.FC<ICommonState> = ({
  services,
  setIsModalOpen,
  setSelectedService,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detects mobile screen
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Box
      sx={{
        py: isMobile || isTablet ? 0 : 4,
        px: 2,
        textAlign: "center",
        mt: isMobile || isTablet ? 0 : 5,
      }}
      id={"service-section"}
    >
      <Typography variant="h4" gutterBottom mb={2} fontWeight={"600"}>
        Services
      </Typography>
      <Typography variant="h6" gutterBottom mb={4} fontWeight={"500"}>
        Discover our outstanding selection of premium services tailored to your
        needs
      </Typography>

      <Stack
        spacing={6}
        useFlexGap
        flexWrap="wrap"
        direction="row"
        justifyContent="center"
      >
        {services.map((service: any, index: number) => (
          <Box
            key={index}
            sx={{
              flex: "1 1 250px", // Reduced minimum width
              maxWidth: "320px", // Reduced max width
              minWidth: "250px",
              border: isMobile ? "1px solid rgb(191, 191, 192)" : "none",
              borderRadius: "8px",
              p: 1,
            }}
          >
            <Card
              sx={{
                padding: 2,
                transition:
                  "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out",
                boxShadow: "none",
                backgroundColor: "#f8f6f1",
                "&:hover": {
                  boxShadow: isMobile
                    ? "4px"
                    : "0px 8px 16px rgba(0, 0, 0, 0.2)",
                  transform: isMobile ? "none" : "translateY(-5px)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="260"
                image={service.image_url}
                alt={service.service_name}
                sx={{
                  borderRadius: "8px",
                }}
                onContextMenu={(event) => event.preventDefault()}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {service.service_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  fontSize={17}
                  mb={2}
                >
                  {service.service_content}
                </Typography>

                {/* Animated Button */}
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, ease: "easeOut" }}
                  viewport={{ once: false }} // Repeats when in view
                >
                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "20px",
                      color: vistaColorCode.vistDarkRedColour,
                      fontWeight: "bold",
                      fontSize: "1rem",
                      "&:hover": {
                        backgroundColor: vistaColorCode.vistLightPinkColour,
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setIsModalOpen(true);
                      (setSelectedService as any)(service.service_name);
                      trackConversion();
                    }}
                  >
                    Enquire Now
                  </Button>
                </motion.div>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default ServiceCards;
