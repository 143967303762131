import { useState } from "react";
import { AboutUs } from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import { GallerySection } from "./Gallery/LandingPageGallery";

import ServiceInquiryModal from "./Modal/ServiceInquiryModal";
import Navbar from "./NavBar/Nav";
import OurProcess from "./OurProcess/OurProcess";
import { PartnerSection } from "./Partners/Partners";
import ServiceCard from "./Services/ServiceCard";
import Footer from "./Footer/Footer";
import { services } from "./constant";

const OuterWrapper = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  return (
    <>
      <div style={{ position: "relative", height: "100vh" }}>
        <Navbar isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <ServiceCard
          services={services}
          setIsModalOpen={setIsModalOpen}
          setSelectedService={setSelectedService}
        />
        <AboutUs setIsModalOpen={setIsModalOpen} />
        <OurProcess />
        <PartnerSection />
        <GallerySection />
        <ContactUs />
        <Footer />
      </div>

      <ServiceInquiryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedService={selectedService}
      />
    </>
  );
};

export default OuterWrapper;
