import React from "react";
import { Modal, Box, useMediaQuery, useTheme } from "@mui/material";

import { vistaColorCode } from "../constant";
import { InnerModalContent } from "./InnerModalContent";

interface ServiceInquiryModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedService?: string;
}

const ServiceInquiryModal: React.FC<ServiceInquiryModalProps> = ({
  isOpen,
  onClose,
  selectedService,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detects mobile screen

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="service-inquiry-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: vistaColorCode.vistLightPinkColour,
          boxShadow: 24,
          borderRadius: "8px",
          width: isMobile ? "90vw" : "100%", // Mobile-friendly width
          maxWidth: isMobile ? "90vw" : "800px", // Limits width on desktop
          padding: isMobile ? "4px" : "8px", // Adjust padding
          paddingBottom: "4px",
          outline: "none",
        }}
      >
        <InnerModalContent
          onClose={onClose}
          selectedService={selectedService || ""}
        />
      </Box>
    </Modal>
  );
};

export default ServiceInquiryModal;
