import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ICommonState, vistaColorCode } from "../constant";
import vistaLogo from "../Images/vistalogo.png";

const Navbar: React.FC<ICommonState> = (props) => {
  const { setIsModalOpen } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const homeRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const navItems = [
    { text: "HOME", id: "home" },
    { text: "SERVICES", id: "service-section" },
    { text: "ABOUT US", id: "about-us-section" },
    { text: "OUR PROCESS", id: "our-process-section" },
    { text: "GALLERY", id: "gallery-section" },
    { text: "CONTACT US", id: "contact-us-section" },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsModalOpen(true);
    }, 27000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box ref={homeRef}>
      {/* Navbar */}
      <AppBar
        position="fixed"
        sx={{
          background: "rgba(255, 255, 255, 0.2)",
          transition:
            "background 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(11px)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "80px",
          }}
          id="home-section"
        >
          {/* Logo & Name */}
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              (homeRef.current as any)?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
          >
            <img
              src={vistaLogo}
              alt="logo"
              style={{ width: 50, cursor: "pointer" }}
            />
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{
                color: scrolled ? "black" : vistaColorCode.vistDarkRedColour,
                ml: 2,
              }}
            >
              Vista Fusion Studio
            </Typography>
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                sx={{
                  fontSize: "1rem",
                  textTransform: "none",
                  transition: "color 0.3s ease, border-bottom 0.3s ease",
                  color: vistaColorCode.vistDarkRedColour,
                  fontWeight: "bold",
                  ":hover": {
                    borderBottom: `2px solid ${vistaColorCode.vistDarkRedColour}`,
                    borderRadius: "0px",
                  },
                }}
                onClick={() => {
                  if (item.id === "home") {
                    (homeRef.current as any)?.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  } else {
                    document.getElementById(item.id)?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Mobile Menu Button */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{
              display: { md: "none" },
              color: vistaColorCode.vistDarkRedColour,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ "& .MuiDrawer-paper": { width: 250 } }}
      >
        <List>
          {navItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (item.id === "home") {
                    (homeRef.current as any)?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  } else {
                    document.getElementById(item.id)?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                  handleDrawerToggle();
                }}
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: vistaColorCode.vistDarkRedColour,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Landing Section */}
      <Box
        sx={{
          position: "relative",
          height: isMobile ? "60vh" : isTablet ? "75vh" : "100vh",
        }}
      >
        <VideoEmbed isMobile={isMobile} isTab={isTablet} />

        {/* Centered Text & Button */}
        <Box
          sx={{
            position: "absolute",
            top: "50%", // Vertically center the content
            left: "50%", // Horizontally center the content
            transform: "translate(-50%, -50%)", // Adjust to perfectly center the button
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            zIndex: 1, // Ensure it's above the overlay
          }}
        >
          {/* Inquiry Button */}
          {/* <Button
            sx={{
              marginBottom: { xs: "18rem", md: "5rem" }, // Adjust margin for mobile and desktop
              marginTop: "20px",
              background: vistaColorCode.vistDarkRedColour,
              color: "white",
              fontSize: { xs: "1rem", md: "1.2rem" }, // Responsive font size
              textTransform: "none",
              borderRadius: "5px",
              ":hover": {
                background: "#a11c2f",
              },
            }}
            onClick={() => setIsModalOpen(true)}
          >
            Enquiry Now
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;

const VideoEmbed: React.FC<any> = ({ isMobile, isTab }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: -1, // Moves it behind content
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Center the iframe within the box
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          paddingTop: isMobile ? "56.25%" : "57%", // 16:9 Aspect Ratio
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/JxLs1nuY4BU?autoplay=1&mute=1&controls=0&modestbranding=1&rel=0&showinfo=0&loop=1&playlist=JxLs1nuY4BU"
          allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
          style={{
            position: "absolute",
            marginTop: isMobile || isTab ? "-15px" : 0,
            top: 0,
            left: 0,
            // border: "none",
            zIndex: 1,
          }}
        ></iframe>
      </Box>
    </Box>
  );
};
