import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Gallery from "./components/Landing/Gallery/Gallery";
import OuterWrapper from "./components/Landing/OuterWrapper";
import { FaWhatsapp } from "react-icons/fa";
import ServiceCard from "./components/Landing/Services/ServiceCard";
import { services } from "./components/Landing/constant";
import ServiceInquiryModal from "./components/Landing/Modal/ServiceInquiryModal";
import { AboutUs } from "./components/Landing/AboutUs/AboutUs";
import OurProcess from "./components/Landing/OurProcess/OurProcess";
import ContactUs from "./components/Landing/ContactUs/ContactUs";

const App: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("");

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/+919030356464", "_blank");
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<OuterWrapper />} />
          <Route
            path="/services"
            element={
              <ServiceCard
                services={services}
                setIsModalOpen={setIsModalOpen}
                setSelectedService={setSelectedService}
              />
            }
          />
          <Route
            path="/about-us"
            element={<AboutUs setIsModalOpen={setIsModalOpen} />}
          />
          <Route path="/our-process" element={<OurProcess />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>

        <div
          style={{
            position: "fixed",
            bottom: "15vw",
            right: "0px",
            zIndex: 1000,
            backgroundColor: "#25D366",
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            padding: "15px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
          onClick={handleWhatsAppClick}
          title="Chat on WhatsApp"
        >
          <FaWhatsapp style={{ color: "white", fontSize: "24px" }} />
        </div>
        <ServiceInquiryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedService={selectedService}
        />
      </Router>
    </>
  );
};

export default App;
